<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId=='createPlanPricing'"
      tabindex="-1"
      role="dialog"
      style="display: block;"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Plan &amp; Pricing</h5>
            <a class="close" @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="addContact"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-12 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Plan Detail</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Plan</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.plan_id.$error }">
                            <select
                              class="form-control border-radius-0"
                              @blur="$v.formData.plan_id.$touch()"
                              v-model.trim="formData.plan_id"
                              required
                            >
                              <option value>Select Plan</option>
                              <option
                                v-for="(plan,key) in dataLists1"
                                :key="key"
                                :value="plan.id"
                              >{{plan.title}}</option>
                            </select>
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.plan_id.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.plan_id.required"
                            >Plan field is required.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Features</label>
                          </div>
                          <div class="col-md-6">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Feature"
                              v-model.trim="formData.features"
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Description</label>
                          </div>
                          <div
                            class="col-md-6"
                            :class="{ invalid: $v.formData.description.$error }"
                          >
                            <textarea
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Description"
                              @blur="$v.formData.description.$touch()"
                              v-model.trim="formData.description"
                              required
                            ></textarea>
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.description.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.description.required"
                            >Description field is required.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.description.minLength"
                            >At least 50 character description.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Status</label>
                          </div>
                          <div class="col-md-6">
                            <input
                              type="radio"
                              name="status"
                              id="activatePlanFeatures"
                              value="1"
                              checked
                              v-model="formData.status"
                            />
                            <label for="activatePlanFeatures">
                              <span class="ml-2">Activate</span>
                            </label>
                            <input
                              type="radio"
                              class="ml-4"
                              name="status"
                              id="deactivatePlanFeatures"
                              value="0"
                              v-model="formData.status"
                            />
                            <label for="deactivatePlanFeatures">
                              <span class="ml-2">Deactivate</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                <p
                  :class="eventMessage.indexOf('success')>=0?'success_level':'error_level'"
                >{{eventMessage}}</p>
              </div>
              {{dataLists1}}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { minLength, numeric, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  computed: {
    ...mapGetters(["eventMessage", "dataLists1", "modalId", "modalState"]),
  },
  mounted() {
    this.$store.commit("getData1", "api/plans");
  },
  data() {
    return {
      formData: {
        plan_id: "",
        features: [],
        description: "",
        status: 1,
      },
    };
  },
  validations: {
    formData: {
      plan_id: { required, numeric },
      description: { required, minLength: minLength(50) },
    },
  },
  methods: {
    addContact() {
      if (!this.$v.formData.$invalid) {
        this.$store.commit("setApiUrl", "api/plan/features");
        this.$store.commit("addData", this.formData);
      } else {
        this.$store.commit("setEventMessage", `Please fill required fields.`);
      }
    },
  },
  watch: {
    eventMessage(value) {
      if (value && value.indexOf("success") >= 0) {
      }
    },
  },
};
</script>