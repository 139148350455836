<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Website</a>
                </li>
                <li class="breadcrumb-item text-capitalize active" aria-current="page">Plan &amp; Pricing</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage plan &amp; pricing details</h4>
          </div>
        </div>
      </div>
      <div class="col-sm-0 tx-right col-lg-7">
        <button
          type="button"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="$store.dispatch('modalOpen','createPlanPricing')"
        >New Plan Feature</button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-9 d-flex mr-0 pr-0">
          <select name="size">
            <option value="1000">1000</option>
          </select>
          <select name="size" class="filter-input ml-2">
            <option value="all branch">All Branch</option>
            <option value="all branch">Kathmandu</option>
            <option value="all branch">Pokhara</option>
          </select>
          <ul class="ml-2">
            <li>
              <a href="javascript:;">
                <i class="fas fa-arrow-left"></i>
              </a>
            </li>
            <li>
              <a href="javascript:;">Today</a>
            </li>
            <li>
              <a href="javascript:;">
                <i class="fas fa-arrow-right"></i>
              </a>
            </li>
          </ul>
          <ul class="ml-2">
            <li>
              <a href="javascript:;">
                <i class="fas fa-arrow-left"></i>
              </a>
            </li>
            <li>
              <a href="javascript:;">Month</a>
            </li>
            <li>
              <a href="javascript:;">
                <i class="fas fa-arrow-right"></i>
              </a>
            </li>
          </ul>
          <div class="ml-2">
            <label class="filter-lavel mb-0 mr-1">From</label>
            <input type="date" placeholder="From" class="filter-input" />
            <label class="filter-lavel mb-0 ml-1">To</label>
            <input type="date" placeholder="To" class="ml-1 filter-input" />
          </div>
        </div>
        <div class="col-md-3 ml-0 pl-0">
          <input type="text" class="float-right" placeholder="Search..." />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="exampleTable">
          <thead>
            <tr>
              <th scope="col" class="text-center">S.N</th>
              <th scope="col">Title</th>
              <th scope="col">Price</th>
              <th scope="col">featured</th>
              <th scope="col">Status</th>
              <th scope="col" class="text-center">Action</th>
            </tr>
          </thead>
          <tbody v-for="(contact,index) in dataLists" :key="index">
            <tr v-if="!removeList.includes(contact.id)">
              <th class="text-center">{{++index}}</th>
              <td>{{contact.name}}</td>
              <td>{{contact.contact}}</td>
              <td>{{contact.featured==1?'Featured':'-'}}</td>
              <td>{{contact.status==1?"Activate":"Deactivate"}}</td>
              <td class="text-center">
                <a
                  href="javascript:;"
                  @click="edit('planPricingDetails',contact.id)"
                  title="More about contact details"
                  class="mr-3"
                >
                  <i class="far fa-edit"></i>
                </a>
                <a href="javascript:;" @click="drop(contact.id)" title="Drop contact">
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination />
      </div>
    </div>
    <Create />
    <Edit />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Edit from "./edit";
import Create from "./create";
import Pagination from "../../../components/pagination/pagination";

export default {
  components: { Create, Edit, Pagination },
  methods: {
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalOpen", modal);
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/contacts/${id}`);
    },
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
  },
  mounted() {
    this.$store.commit("getData", `api/contacts`);
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>